<template>
  <div style="background: #fff;height: 100%;
">
    <van-nav-bar style="background: #fff;" title="手动核销" :fixed="true" :z-index="999"></van-nav-bar>
    <div style="overflow: hidden;background: #fff;padding-top:45px;height: 100%;">
      <div class="hed">
        <div style="padding-left: 12px;    margin-top: 20px;">请输入车牌：</div>
        <div class="car-number">
          <van-row class="user_links2">
            <van-col span="24" @click.stop="shurucp" style="float: right">
              <div class="item vux-1px-l" v-for="(v, i) in carNumberArr" :key="i">
                <div class="flex">{{carNumber[i] || ''}}</div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
    <key-board v-show="showKeyBoard" @getPlateb="getPlateb" v-model="carNumber" :car_type="type"></key-board>
    <div class="boto">
      <van-button type="danger" @click="tijiao">查 询</van-button>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";
import KeyBoard from "@/components/KeyBoard.vue";
export default {
  components: {
    KeyBoard
  },
  data() {
    return {
      gid: this.$route.query.gid,
      addForm: {},
      carNumberArr: ["", "", "", "", "", "", "", ""],
      carNumber: [],
      type: "0",
      showKeyBoard: true,
      uid: "",
      token: ""
    };
  },
  methods: {},
  created() {
    // this.token = window.androidjs.getLocalData("getToken");
    // this.token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNDk3IiwiZXhwIjoxNjE1OTUwMzgxfQ.MiojnOiEsPM6wvcnqiCGxNaNaZJhUi9Q5a3XkkhgpDFqIz4wk6O2ZTJ3seYPtIUCeA2b7D4-IF0TwDPagJy6nQ'
    // sessionStorage.setItem(" ", this.token);
  },
  watch: {
    carNumber() {
      console.log(this.type);
      if (
        (this.type != 2 && this.carNumber.length === 8) ||
        this.carNumber.length === 8
      ) {
        this.showKeyBoard = false;
      }
    },
    dingshi: function(newVal, oldVal) {
      console.log(newVal);
      console.log(oldVal);
    },
    type() {
      if (this.type == 2) {
        if (this.carNumberArr.length == 7) {
          this.carNumberArr.push("");
          this.showKeyBoard = true;
        }
      } else if (this.carNumberArr.length == 8) {
        this.carNumberArr.pop();
        this.showKeyBoard = false;
      }
    }
  },
  methods: {
    shurucp() {
      this.showKeyBoard = true;
    },
    getPlateb() {
      this.showKeyBoard = false;
    },

    tijiao() {
      var carNo = this.carNumber.join("");
      var vm = this;
      if (carNo == "") {
        this.$toast("请先输入车牌号码！");
        return;
      }
      let data = {
        gid: this.gid,
        carNo: carNo
      };
      userModel.gidAndCar(data).then(res => {
        if (res.code == 200) {
          location.href =
            "http://customer.car-posthouse.cn/matching/verification.html?orderNumber=" +
            res.data.orderNumber;
          console.log(res);
        }
      });
    },

    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style  lang="less" scoped>
#app {
  background: #f5f5f5;
  height: 100%;
}
.hed {
  background: #fff;
}
// --------------
.car-number {
  height: 60px;
}
.item {
  border: 1px solid #013bba;
  border-radius: 2px;
  width: 10%;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  text-align: center;
  float: left;
  margin-right: 5px;
}
.item:last-child {
  background: #4b84ff;
  border: 1px solid #013bba;
}
#key-board {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.gzname {
  font-size: 12px;
  background: #fff;
  display: inline-block;
  padding: 1px 12px;
  color: #013bb9;
  border-radius: 6px;
  margin-bottom: 12px;
}
.gzname_a {
  font-size: 12px;
  color: #fff;
  margin-top: 10px;
}
.chepeiib {
  line-height: 34px;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}
.user_links2 {
  padding: 9px 0;
  
  font-size: 14px;
  margin-left: 5%;
  position: relative;
}
.user_links {
  padding: 12px 0;
  font-size: 14px;
  margin-left: 10px;
  position: relative;
  border-bottom: 1px solid #dcdcdc;
}
.chepeii {
  line-height: 22px;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}
.van-cell {
  padding: 0;
  // background-color: #f1f1f1;
}
// ----------------------------

.h_name {
  text-align: center;
  color: #013bba;
  margin-top: 10px;
}
.xinbie {
  width: 14px;
  float: left;
  margin-top: 11px;
  margin-left: 8px;
}
.boto {
  position: fixed;
  bottom: 0;
  z-index: 99;
  width: 50%;
  left: 50%;
  margin-left: -25%;
  margin-bottom: 250px;
  width: 100%;
  line-height: 40px;
  font-size: 12px;
}
.boto .van-button {
  width: 50%;
  padding: 0 6px;
  margin: auto;
}
</style>
